<template>
  <div>
    <!-- <h1>Monitoring Hulu Migas</h1> -->
    <BCardActions
      title="Permintaan Pelayanan Kapal & Barang"
      sub-title="Semoga hari anda menyenangkan dan tetap semangat 📌"
      action-collapse
      class="mt-1"
      :collapsed="false"
      :no-actions="true"
    >
      <YoSalTable />
    </BCardActions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import YoSalTable from '@views/pages/monitoring/jalur-sungai/hulu-migas/YoSalTable.vue'

export default {
  components: {
    BCardActions,
    YoSalTable
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  }
}
</script>

<!-- <template>
  <YoTable
    ref-specify="HuluMigasTable"
    :api-endpoint="apiEndpoint"
    :mock-api="false"
    :title-tbl="this.$t('Dashboards') + ' HuluMigas'"
    sub-title-tbl="Permintaan Pelayanan Kapal & Barang"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :disable-edit="disableEdit"
    :force-disable-crud="true"
    enable-create-form="dashboard-monitoring-rrv-hulu-migas-create"
    enable-view-form="dashboard-monitoring-rrv-hulu-migas-view"
    placeholder-search="LHCode"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/sungai/CompTableHuluMigas.vue'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      apiEndpoint: 'form_hulu_migas', //
      disableEdit: true,

      fields: [
        {
          searchModel: 'form_status',
          search: {
            type: 'text'
          },
          key: 'form_status',
          // stickyColumn: true,
          // minWidth: '10px',
          width: '24em',
          minWidth: '24em',
          label: 'Status',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        // {
        //   searchModel: 'dm_river_boat_hm.no_doc',
        //   search: {
        //     type: 'text'
        //   },
        //   key: 'dm_river_boat_hm.no_doc',
        //   label: 'Nomor Dokumen',
        //   sortable: true,
        //   width: '22em',
        //   minWidth: '22em',
        //   input: { type: 'input-noarea' }
        // },
        {
          searchModel: 'dm_river_boat_hm.boat_name',
          search: {
            type: 'text'
          },
          key: 'dm_river_boat_hm.boat_name',
          stickyColumn: true,
          label: 'Nama Kapal',
          // width: '18em',
          // minWidth: '18em',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'dm_river_boat_hm.no_pkk',
          search: {
            type: 'text'
          },
          key: 'dm_river_boat_hm.no_pkk',
          // width: '15em',
          // minWidth: '15em',
          label: 'Nomor PKK',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'kegiatan',
          search: {
            type: 'text'
          },
          key: 'kegiatan',
          width: '28em',
          minWidth: '28em',
          label: 'Kegiatan',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          key: 'rencana_sandar',
          label: 'Rencana Sandar',
          sortable: true,
          placeholderEmpty: 'DIISI OLEH ADMIN SAL',
          input: {
            type: 'time',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'rencana_sandar',
          search: {
            type: 'date'
          },
          width: '15em',
          minWidth: '15em'
          // width: '100em',
        },
        {
          searchModel: 'show_details',
          search: {
            type: 'text'
          },
          key: 'show_details',
          // stickyColumn: true,
          width: '8em',
          minWidth: '8em',
          label: 'Details',
          sortable: true,
          _showDetails: true,
          input: { type: 'input-noarea' }
        }
      ]
    }
  },
  computed: {
    newDataTemplate() {
      return {
        // name: '',
        // route: '',
      }
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.form_hulu_migass
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script> -->
