<template>
  <div class="monitoring">
    <b-row>
      <b-col md="8" cols="12">
        <b-form-group>
          <div class="d-flex align-items-center">
            <!--  -->
          </div>
        </b-form-group>
      </b-col>
      <b-col class="text-right mb-2" md="4" cols="12">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-button
            id="tambah-data"
            class="button-tambah mr-1"
            v-if="
              $store.state.app.roleName !== 'viewer' &&
              !$can('SAL', '') &&
              $store.state.app.roleName !== 'jetty man'
            "
            :variant="'primary'"
            :to="{
              name: enableCreateForm,
              params: {
                previous_link: {
                  name: 'dashboard-monitoring-rrv-hulu-migas'
                }
              }
            }"
          >
            <!-- @click="newData(status)" -->
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                Buat Rencana Kedatangan Kapal Hulu Migas
              </strong>
            </span>
          </b-button>
          <!-- <b-button
            id="tambah-data"
            class="button-tambah mr-1"
            :variant="'warning'"
          >
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                Export to Excel (
                {{
                  selectedRows.length === 0
                    ? 'ALL Rows'
                    : `Only Row Selected (${selectedRows.length})`
                }}
                )
              </strong></span
            >
          </b-button> -->
        </div>
      </b-col>
    </b-row>
    <div v-if="yo_isLoading" class="progress-wrapper">
      <b-card-text class="mb-0">
        Memuat data… {{ yo_isLoadingValue + '%' }}
      </b-card-text>
      <b-progress
        :key="yo_isLoadingValue"
        animated
        :value="yo_isLoadingValue"
        variant="info"
        max="100"
        :class="'progress-bar-info'"
      />
    </div>

    <!-- table -->
    <vue-good-table
      :key="yoRender"
      class="yo-good-table"
      mode="remote"
      :total-rows="total_records"
      :columns="columns"
      :rows="rows"
      :is-loading="yo_isLoading"
      :rtl="direction"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder:
          'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      }"
      @on-search="onSearchTable"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        // perPageDropdown: [3, 7, 9],
        // dropdownAllowAll: false,
        // setCurrentPage: 1,
        jumpFirstOrLast: true,
        // firstLabel : 'First Page'
        // lastLabel : 'Last Page'
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        // ofLabel: 'of',
        // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`
      }"
      :group-options="{
        enabled: thereIsGroup
      }"
      compact-mode
      theme="polar-bear"
      style-class="vgt-table bordered table-hover"
      :line-numbers="true"
      :fixed-header="false"
      max-height="700px"
      @on-selected-rows-change="selectionChanged"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <!--
         -->
      <template slot="table-header-row" slot-scope="props">
        Kegiatan
        <BBadge variant="primary" class="mr-1">
          <strong>{{ props.row.label }}</strong>
        </BBadge>
        <BBadge variant="secondary">
          Jumlah Data : {{ props.row.children.length }}
        </BBadge>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.row.pemilik">
          <span v-if="props.column.field === 'form_hulu_miga.form_status'">
            <h6>
              <b-row>
                <b-col>
                  <strong>
                    <b-badge
                      pill
                      :variant="formStatusVariant(ftbStatus(props.row))"
                      class="d-block py-1"
                      @click="copyText(ftbStatus(props.row))"
                    >
                      <span v-text="ftbStatus(props.row)" />
                    </b-badge>
                  </strong>
                </b-col>
              </b-row>
            </h6>
            <!-- khusus tug boat only -->
            <b-row v-if="!props.row.no_rpkro_tb || !props.row.no_spog_tb">
              <b-col>
                <small>
                  <span v-if="$can('Hulu Migas', '')">
                    <b-spinner :variant="'warning'" class="" type="grow" />
                    <strong>Verifikasi (Warning)</strong>:
                    <ol>
                      <li v-if="!props.row.no_rpkro_tb">
                        <span>Admin SAL Belum menerbitkan Nomor RPKRO</span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <li v-if="!props.row.no_spog_tb">
                        <span>Belum menerbitkan Nomor SPOG</span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>

                      <!-- <li v-if="!props.row.no_rpkro_tb">
                        <span>Admin SAL Belum menerbitkan Nomor RPKRO</span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <li v-if="props.row.no_rpkro_tb && !props.row.no_spog_tb">
                        <span>Belum menerbitkan Nomor SPOG</span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li> -->
                    </ol>
                  </span>
                  <span v-if="$can('SAL', '')">
                    <b-spinner :variant="'warning'" class="" type="grow" />
                    <strong>Verifikasi (Warning)</strong>:
                    <ol>
                      <li v-if="!props.row.no_rpkro_tb">
                        <span>Admin SAL Belum menerbitkan Nomor RPKRO</span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <li v-if="!props.row.no_spog_tb">
                        <span
                          >Admin Hulu Migas belum menerbitkan Nomor SPOG</span
                        >
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <!-- <li v-if="!props.row.no_rpkro_tb">
                        <span>Admin SAL Belum menerbitkan Nomor RPKRO</span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <li v-if="props.row.no_rpkro_tb && !props.row.no_spog_tb">
                        <span
                          >Admin Hulu Migas belum menerbitkan Nomor SPOG</span
                        >
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li> -->
                    </ol>
                  </span>
                </small>
              </b-col>
            </b-row>
          </span>
          <span
            v-else-if="
              props.column.field === 'form_hulu_miga.dm_river_boat_hm.boat_name'
            "
          >
            <strong>
              <span
                class="cursor-pointer"
                @click="copyText(props.row.tug_boat.name_tug_boat)"
              >
                {{ props.row.tug_boat.name_tug_boat }}
              </span>
              <div class="divider">
                <div class="divider-text">Nomor PKK</div>
              </div>
              <span>
                <b-badge
                  href="#"
                  :variant="'light-primary'"
                  @click="copyText(props.row.no_pkk_tug_boat)"
                  class="d-block"
                >
                  {{ props.row.no_pkk_tug_boat }}
                </b-badge>
              </span>
            </strong>
            <div class="divider">
              <div class="divider-text">
                <strong> Kegiatan </strong>
              </div>
            </div>
            <span>
              <strong>
                <b-badge
                  href="#"
                  :variant="'light-primary'"
                  @click="copyText('TAMBAT')"
                  class="d-block"
                >
                  <span v-text="'TAMBAT'" />
                </b-badge>
              </strong>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'form_hulu_miga.rencana_sandar'"
          >
            <strong>
              <span>[TugBoat]&nbsp;</span>
              <div class="divider">
                <div class="divider-text">Rencana Sandar</div>
              </div>
              <b-badge
                href="#"
                :variant="'light-primary'"
                @click="copyText(dateHumanReadAble(props.row.rencana_sandar))"
                class="d-block"
              >
                <feather-icon icon="CalendarIcon" class="mr-50" />
                {{ dateHumanReadAble(props.row.rencana_sandar) }}
                <!-- <feather-icon
                  class="cursor-pointer mr-1"
                  :icon="
                    dateHumanReadAble(props.row.rencana_sandar).includes('AM')
                      ? 'SunIcon'
                      : 'MoonIcon'
                  "
                /> -->
              </b-badge>
            </strong>
            <div class="divider">
              <div class="divider-text">Rencana Posisi Jetty</div>
            </div>
            <span>
              <b-row>
                <b-col cols="6"
                  ><b-badge
                    href="#"
                    class="d-block"
                    variant="light-primary"
                    @click="copyText(props.row.rencana_position_jetty)"
                  >
                    #{{ props.row.rencana_position_jetty }}
                  </b-badge></b-col
                >
                <b-col cols="6"
                  ><b-badge
                    href="#"
                    class="d-block"
                    variant="light-primary"
                    @click="copyText(props.row.rencana_baris_position_jetty)"
                  >
                    #{{ props.row.rencana_baris_position_jetty }}
                  </b-badge></b-col
                >
              </b-row>
            </span>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                :variant="
                  ftbStatus(props.row) !== 'KAPAL BERANGKAT'
                    ? ftbStatus(props.row) === 'KEGIATAN DIBATALKAN'
                      ? 'outline-danger'
                      : 'outline-warning'
                    : 'outline-success'
                "
                block
                :to="
                  props.row.model_id != null
                    ? {
                        name: enableViewForm,
                        params: {
                          id: props.row.model_id,
                          api: 'form_chain_hm',
                          previous_link: {
                            name: 'dashboard-monitoring-rrv-hulu-migas'
                          }
                        }
                      }
                    : {
                        name: 'dashboard-monitoring-rrv-tug-boat-view',
                        params: {
                          id: props.row.id,
                          api: 'form_tug_boat',
                          previous_link: {
                            name: 'dashboard-monitoring-rrv-hulu-migas'
                          }
                        }
                      }
                "
                size="lg"
              >
                <feather-icon
                  class="cursor-pointer"
                  :icon="'PenToolIcon'"
                  size="25"
                />
                Realisasikan
              </b-button>
            </span>
            <b-button
              class="mt-1"
              variant="outline-primary"
              block
              size="lg"
              :to="props.row.model_id ? {
                name: 'dashboard-monitoring-rrv-hulu-migas-summary',
                params: {
                  id: props.row.model_id,
                  api: 'form_chain_hm',
                  previous_link: {
                    name: 'dashboard-monitoring-rrv-hulu-migas'
                  }
                }
              } : {
                name: 'dashboard-monitoring-rrv-tug-boat-summary',
                params: {
                  id: props.row.id,
                  api: 'form_chain_hm',
                  previous_link: {
                    name: 'dashboard-monitoring-rrv-hulu-migas'
                  }
                }
              } "
            >
              <feather-icon
                class="cursor-pointer"
                icon="ActivityIcon"
                size="25"
              />
              Summary
            </b-button>
            <br />
            <b-badge pill class="d-block" :variant="'light-primary'">
              <strong>
                <span v-if="props.row.model_id">
                  #{{ props.row.model_id }}
                  #{{ props.row.form_chain_hm?.form_hulu_migas_id }}
                  #{{ props.row.id }}
                </span>
                <span v-else> #{{ props.row.id }}</span>
              </strong>
            </b-badge>
          </span>
          <span v-else>
            <strong>{{ props.formattedRow[props.column.field] }}</strong>
          </span>
        </span>
        <!-- Column: Common -->
        <span v-else>
          <span v-if="props.column.field === 'form_hulu_miga.form_status'">
            <h6>
              <b-row>
                <b-col>
                  <strong>
                    <b-badge
                      pill
                      :variant="
                        formStatusVariant(props.row.form_hulu_miga?.form_status)
                      "
                      @click="copyText(props.row.form_hulu_miga?.form_status)"
                      class="d-block py-1"
                    >
                      <span v-text="props.row.form_hulu_miga?.form_status" />
                    </b-badge>
                  </strong>
                </b-col>
              </b-row>
            </h6>
            <h5
              v-if="
                props.row.form_hulu_miga.fhm_keg_pipelines.length === 0 &&
                props.row.form_hulu_miga.fhm_keg_manhs.length === 0 &&
                props.row.form_hulu_miga.form_status !== 'KAPAL BERANGKAT'
              "
            >
              <b-alert
                variant="warning"
                show
                class="mt-1"
                @click="openModalRiver(data.item)"
              >
                <div class="alert-body">
                  <strong>
                    <feather-icon icon="AlertTriangleIcon" class="mr-25" />
                    <!-- <span v-text="`RENCANA KEGIATAN BELUM DIISI`" /> -->
                    <span v-text="`RENCANA KEGIATAN BELUM DIISI`" />
                  </strong>
                </div>
              </b-alert>
            </h5>
            <h5
              v-if="props.row.form_hulu_miga.realisasi_posisi_kapals.length > 1"
            >
              <b-alert
                variant="info"
                show
                class="mt-1"
                @click="openModalRiver(data.item)"
              >
                <div class="alert-body">
                  <strong>
                    <feather-icon icon="AnchorIcon" class="mr-25" />
                    <span v-text="` KAPAL PERNAH PINDAH`" />
                  </strong>
                </div>
              </b-alert>
            </h5>
            <b-row
              v-if="verifiedVariant(props.row.flag_verified) === 'warning'"
            >
              <b-col>
                <small>
                  <span v-if="!$can('SAL', '')">
                    <span
                      v-if="
                        props.row.verification_hms.filter(
                          (data) =>
                            (data.flag_msg === 'tenant' &&
                              data.state_ok === false) ||
                            data.flag_msg === 'public'
                        ).length === 1
                      "
                    >
                      <b-spinner
                        :variant="verifiedVariant(props.row.flag_verified)"
                        class=""
                        type="grow"
                      /><strong
                        >Verifikasi ({{ props.row.flag_verified }})</strong
                      >:
                      <ol>
                        <li
                          v-for="(
                            data, index
                          ) in props.row.verification_hms.filter(
                            (data) =>
                              data.flag_msg === 'tenant' ||
                              data.flag_msg === 'public'
                          )"
                          :key="index"
                        >
                          <span v-html="data.manual_message"></span>
                          <feather-icon
                            :icon="
                              data.state_ok ? 'CheckCircleIcon' : 'XCircleIcon'
                            "
                            class="ml-25"
                          />
                        </li>
                      </ol>
                    </span>
                    <span v-else>
                      <!--  -->
                    </span>
                    <span
                      v-if="
                        props.row.verification_hms.filter(
                          (data) =>
                            (data.flag_msg === 'sal' ||
                              data.flag_msg === 'public') &&
                            data.state_ok === false &&
                            !data.manual_message.includes('[TB]')
                        ).length != 0
                      "
                    >
                      <b-spinner
                        :variant="verifiedVariant('Request Re-Verification')"
                        class=""
                        type="grow"
                      /><strong>Informasi Document belum lengkap</strong>
                      <ol>
                        <li
                          v-for="(
                            data, index
                          ) in props.row.verification_hms.filter(
                            (data) =>
                              (data.flag_msg === 'sal' ||
                                data.flag_msg === 'public') &&
                              data.state_ok === false &&
                              !data.manual_message.includes('[TB]')
                          )"
                          :key="index"
                        >
                          <span v-html="data.manual_message"></span>
                          <feather-icon
                            :icon="
                              data.state_ok ? 'CheckCircleIcon' : 'XCircleIcon'
                            "
                            class="ml-25"
                          />
                        </li>
                      </ol>
                    </span>
                  </span>
                  <span v-else>
                    <b-spinner
                      :variant="verifiedVariant(props.row.flag_verified)"
                      class=""
                      type="grow"
                    /><strong>Verifikasi ({{ props.row.flag_verified }})</strong
                    >:
                    <ol>
                      <li
                        v-for="(
                          data, index
                        ) in props.row.verification_hms.filter(
                          (data) =>
                            (data.flag_msg === 'sal' ||
                              data.flag_msg === 'public') &&
                            data.state_ok === false &&
                            !data.manual_message.includes('[TB]')
                        )"
                        :key="index"
                      >
                        <span v-html="data.manual_message"></span>
                        <feather-icon
                          :icon="
                            data.state_ok ? 'CheckCircleIcon' : 'XCircleIcon'
                          "
                          class="ml-25"
                        />
                      </li>
                    </ol>
                    <span
                      v-if="
                        props.row.verification_hms.filter(
                          (data) =>
                            (data.flag_msg === 'tenant' &&
                              data.state_ok === false) ||
                            data.flag_msg === 'public'
                        ).length === 1
                      "
                    >
                      <b-spinner
                        :variant="verifiedVariant('Request Re-Verification')"
                        class=""
                        type="grow"
                      /><strong>Informasi Document belum lengkap :</strong>
                      <ol>
                        <li
                          v-for="(
                            data, index
                          ) in props.row.verification_hms.filter(
                            (data) =>
                              data.flag_msg === 'tenant' ||
                              data.flag_msg === 'public'
                          )"
                          :key="index"
                        >
                          <span v-html="data.manual_message"></span>
                          <feather-icon
                            :icon="
                              data.state_ok ? 'CheckCircleIcon' : 'XCircleIcon'
                            "
                            class="ml-25"
                          />
                        </li>
                      </ol>
                    </span>
                  </span>
                </small>
              </b-col>
            </b-row>
          </span>
          <span
            v-else-if="
              props.column.field === 'form_hulu_miga.dm_river_boat_hm.boat_name'
            "
          >
            <strong>
              <span
                class="cursor-pointer"
                @click="
                  copyText(props.row.form_hulu_miga.dm_river_boat_hm.boat_name)
                "
              >
                {{ props.row.form_hulu_miga.dm_river_boat_hm.boat_name }}
              </span>
              <div class="divider">
                <div class="divider-text">Nomor PKK</div>
              </div>
              <span>
                <b-badge
                  href="#"
                  :variant="'light-primary'"
                  @click="copyText(props.row.form_hulu_miga.no_pkk)"
                  class="d-block"
                >
                  {{ props.row.form_hulu_miga.no_pkk }}
                </b-badge>
              </span>
              <div class="divider">
                <div class="divider-text">Kegiatan</div>
              </div>
              <span>
                <b-badge
                  href="#"
                  :variant="'light-primary'"
                  @click="copyText(props.row.form_hulu_miga.kegiatan)"
                  class="d-block"
                >
                  {{ props.row.form_hulu_miga.kegiatan }}
                </b-badge>
              </span>
            </strong>
          </span>
          <span
            v-else-if="props.column.field === 'form_hulu_miga.rencana_sandar'"
          >
            <strong>
              <span>[{{ props.row.form_hulu_miga.jenis_kapal }}]&nbsp;</span>
              <div class="divider">
                <div class="divider-text">Rencana Sandar</div>
              </div>
              <b-badge
                href="#"
                :variant="'light-primary'"
                @click="
                  copyText(
                    dateHumanReadAble(props.row.form_hulu_miga.rencana_sandar)
                  )
                "
                class="d-block"
              >
                <feather-icon icon="CalendarIcon" class="mr-50" />
                {{ dateHumanReadAble(props.row.form_hulu_miga.rencana_sandar) }}
                <!-- <feather-icon
                  class="cursor-pointer mr-1"
                  :icon="
                    dateHumanReadAble(
                      props.row.form_hulu_miga.rencana_sandar
                    ).includes('AM')
                      ? 'SunIcon'
                      : 'MoonIcon'
                  "
                /> -->
              </b-badge>
              <div class="divider">
                <div class="divider-text">Rencana Posisi Jetty</div>
              </div>
              <span>
                <b-row>
                  <b-col cols="6"
                    ><b-badge
                      href="#"
                      class="d-block"
                      variant="light-primary"
                      @click="
                        copyText(
                          props.row.form_hulu_miga.rencana_position_jetty
                        )
                      "
                    >
                      #{{ props.row.form_hulu_miga.rencana_position_jetty }}
                    </b-badge></b-col
                  >
                  <b-col cols="6"
                    ><b-badge
                      href="#"
                      class="d-block"
                      variant="light-primary"
                      @click="
                        copyText(
                          props.row.form_hulu_miga.rencana_baris_position_jetty
                        )
                      "
                    >
                      #{{
                        props.row.form_hulu_miga.rencana_baris_position_jetty
                      }}
                    </b-badge></b-col
                  >
                </b-row>
              </span>
            </strong>
            <!-- <strong>
            <div class="divider">
              <div class="divider-text">
                Realisasi
                <br />
                <small>
                  {{
                    durationAsString(
                      props.row.form_hulu_miga.sandar_start_time,
                      props.row.form_hulu_miga.sandar_end_time
                    )
                  }}
                </small>
              </div>
            </div>
            #Sandar
            {{ dateHumanReadAble(props.row.form_hulu_miga.sandar_start_time) }}
            <feather-icon
              class="cursor-pointer mr-1"
              :icon="
                dateHumanReadAble(
                  props.row.form_hulu_miga.sandar_start_time
                ).includes('AM')
                  ? 'SunIcon'
                  : 'MoonIcon'
              "
            />

            <hr />
            <span>
              {{ currentlyPosisiKapal(props.row.form_hulu_miga) }}
            </span>
          </strong> -->
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                :variant="
                  props.row.form_hulu_miga.form_status !== 'KAPAL BERANGKAT'
                    ? 'outline-warning'
                    : 'outline-success'
                "
                :to="{
                  name: enableViewForm,
                  params: {
                    id: props.row.id,
                    api: 'form_chain_hm',
                    previous_link: {
                      name: 'dashboard-monitoring-rrv-hulu-migas'
                    },
                    kegiatanDetail: currentlyKegiatanKapal(
                      props.row.form_hulu_miga
                    )
                  }
                }"
                size="lg"
                block
              >
                <feather-icon
                  class="cursor-pointer"
                  :icon="
                    props.row.form_hulu_miga.form_status ===
                    'NO PERIJINAN BELUM LENGKAP'
                      ? 'PenToolIcon'
                      : 'PenToolIcon'
                  "
                  size="25"
                />
                Realisasikan
              </b-button>
              <br />
              <b-button
                class="mt-1"
                variant="outline-primary"
                block
                size="lg"
                :to="{
                  name: 'dashboard-monitoring-rrv-hulu-migas-summary',
                  params: {
                    id: props.row.id,
                    api: 'form_chain_hm',
                    previous_link: {
                      name: 'dashboard-monitoring-rrv-hulu-migas'
                    }
                  }
                }"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="ActivityIcon"
                  size="25"
                />
                Summary
              </b-button>
              <br />
              <b-badge pill class="d-block" :variant="'light-primary'">
                <strong>
                  <span v-if="props.row.form_hulu_migas_id">
                    #{{ props.row.id }}
                    #{{ props.row.form_hulu_migas_id }}
                    #{{ props.row.form_tug_boat_id }}
                  </span>
                  <span v-else> #{{ props.row.id }}</span>
                  <!-- <span> #{{ props.row.id }}</span> -->
                </strong>
              </b-badge>
            </span>
          </span>

          <span v-else>
            <strong>{{ props.formattedRow[props.column.field] }}</strong>
          </span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'

export default {
  name: 'CompGoodTableCustomHM',
  components: {
    VSelect,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BCardActions,
    flatPickr,
    BSpinner,
    BBadge
  },
  props: {
    resolveFetchResponse: Function,
    resolveFetchPayload: Function,
    dateHumanReadAble: Function,
    columns: Array,
    thereIsGroup: Boolean,
    enableCreateForm: String,
    enableViewForm: String
  },
  setup() {
    const { isVerticalMenuCollapsed, anIncomingNotificationRiver } =
      useAppConfig()
    return {
      isVerticalMenuCollapsed /* Fixed Header !false Good Table #issue debounce 300s */,
      anIncomingNotificationRiver /* FirebaseFCM */
    }
  },
  data() {
    return {
      yoRender: 1,
      /* PROGRESS | RENDERING | REFS */
      yoTimeoutDebounce: null,
      yoUserData: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dir: false,
      /* PROGRESS | RENDERING | REFS */

      /* GOOD TABLE DATA() DONT MODIFIED */
      onFiltering: false,
      total_records: 0,
      rows: [],
      selectedRows: [],
      serverParams: {
        columnFilters: {},
        searchTerm: '',
        sort: {
          type: 'DESC',
          field: 'created_at'
        },
        page: 1,
        perPage: 10
      }
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formStatusVariant() {
      return (any) => this.$variantColumnFormStatus[any]
    },
    verifiedVariant() {
      return (any) => this.$variantVerified[any]
    },
    currentlyPosisiKapal() {
      return (props) => {
        let string
        const data = props.realisasi_posisi_kapals
        const length = data.length
        if (length !== 0) {
          const posisi = data[length - 1].posisi_skrg
          const baris = data[length - 1].posisi_skrg_baris
          string = `#POSISI_${posisi} #${baris}`
        } else {
          string = '#POSISI_'
        }
        return string
      }
    },
    currentlyKegiatanKapal() {
      return (props) => {
        let kegiatan = []

        let fhm_keg_pipelines = props.fhm_keg_pipelines
        let fhm_keg_manhs = props.fhm_keg_manhs

        if (fhm_keg_pipelines) {
          fhm_keg_pipelines.forEach((itm) => {
            const { kegiatan_hm } = itm
            kegiatan.push(kegiatan_hm.keg_name)
          })
        }
        if (fhm_keg_manhs) {
          fhm_keg_manhs.forEach((itm) => {
            const { kegiatan_hm } = itm
            kegiatan.push(kegiatan_hm.keg_name)
          })
        }
        if (fhm_keg_pipelines || fhm_keg_manhs) {
          /* eliminasi duplicate here */
          kegiatan = kegiatan.filter(function (value, index, array) {
            return array.indexOf(value) === index
          })
        }
        if (kegiatan.length === 0) {
          kegiatan = props.kegiatan
        }
        return kegiatan.toString().replace(/,/g, ', ') || kegiatan
      }
    }
  },
  watch: {
    isVerticalMenuCollapsed() {
      console.log('collapse', this.isVerticalMenuCollapsed)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        // this.yoRender += 1
        // this.serverParams = {
        //   ...this.serverParams,
        // }
      }, 300)
    },
    anIncomingNotificationRiver() {
      if (!this.serverParams.searchTerm) {
        console.log(
          'RMSungai.vue : an incoming mesage key : ',
          this.anIncomingNotificationRiver
        )
        let high_light_data =
          this.anIncomingNotificationRiver.data[
            `gcm.notification.screen_re_fetch`
          ]
        let will_be_refresh = ['form_chain_hm']
        let go_refresh = will_be_refresh.some((data) =>
          high_light_data.includes(data)
        )
        console.log('vgo_refresh::', go_refresh)
        if (go_refresh) {
          this.fetchData(true, '')
        }
      }
    },
    'document.body.style.zoom': function () {
      console.log('screen change')
    }
  },
  mounted() {
    // document.body.style.zoom = '100%'
    const isBack = !!this.$router.currentRoute.params?.reFetch
    console.log('mounted', isBack)
    if (isBack) {
      // this.fetchData(true, '')
    }
  },
  destroyed() {
    // document.body.style.zoom = '100%'
  },
  created() {
    const yoUserData = getUserData()
    this.yoUserData = yoUserData
    this.fetchData(true, '')
  },
  beforeDestroy() {
    this.isTimerDone()
  },
  methods: {
    /* START FROM HERE DONT MODIFIED */
    moment,
    ftbStatus(row) {
      const end = row.tug_sandar_end_time
      const start = row.tug_sandar_start_time
      if (row.is_cancel === true) {
        return 'KEGIATAN DIBATALKAN'
      } else {
        if (start && end) {
          return 'KAPAL BERANGKAT'
        }
        if (start) {
          return 'KAPAL SANDAR'
        }
      }
      return 'KAPAL RENCANA SANDAR'
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    selectionChanged(params) {
      console.log('selectionChanged: ', params)
      this.selectedRows = params.selectedRows
    },
    onPageChange(params) {
      console.log('onPageChange: ', params)
      // this.updateParams({ page: params })
      this.updateParams({ page: params.currentPage })
      this.fetchData(true, '')
    },

    onPerPageChange(params) {
      console.log('onPerPageChange: ', params)
      // this.updateParams({ perPage: params })
      this.updateParams({ perPage: params.currentPerPage })
      this.rows = []
      this.total_records = 0
      this.fetchData(true, '')
    },

    onSortChange(params) {
      console.log(`onSortChange : ${JSON.stringify(params)}`)
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.fetchData(true, '')
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.fetchData(true, '')
    },
    onSearchTable(params) {
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.updateParams({ searchTerm: params.searchTerm })
        console.log('search: ', params.searchTerm)
        this.fetchData(true, '')
      }, 300)
    },
    /* END FROM HERE DONT MODIFIED */

    /* FETCH DATA | AXIOS */
    fetchData() {
      this.isTimerStart()
      const payload = this.resolveFetchPayload()
      const uri = payload.API_SAL

      this.serverParams = {
        ...this.serverParams
        // ...originalServerParams
      }

      useJwt.http
        .get(`${uri}`, {
          params: { ...this.serverParams }
        })
        .then((res) => {
          const { total_records } = res.data
          this.total_records = total_records
          const get_rows = this.resolveFetchResponse(res).map((data) => ({
            ...data
          }))
          this.rows = get_rows
          useJwt.http
            .get(`form_tug_boat/show/hm`, {
              params: { ...this.serverParams }
            })
            .then((res) => {
              const { form_tug_boats, total_records } = res.data
              this.total_records += total_records
              this.rows.push(...form_tug_boats)
              this.rows.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at)
              })
              console.log('this.rows:::', this.rows)
            })
          this.isTimerDone()
          // this.yoRender += 1
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-global-search {
            border-top: 5px solid #2f2f2f1b;
            border-left: 5px solid #2f2f2f1b;
            border-right: 5px solid #2f2f2f1b;
            .vgt-global-search__input {
              border: none !important;
              .input__icon {
                font-weight: 700 !important;
              }
              .vgt-input {
                font-weight: 700 !important;
                color: black;
                border: double;
              }
            }
          }
          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;
            thead {
              th {
                border: $color-yo-border !important;
              } /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }
            tbody {
              th {
                border: $color-yo-border !important;
              } /* left checkbox */
              td {
                border: $color-yo-border !important;
              } /* right checkbox */
            }
          }
          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }
            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
