<template>
  <YoTable
    ref-specify="YoTable"
    :columns="columns"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-payload="resolveFetchPayload"
    :there-is-group="false"
    :dateHumanReadAble="dateHumanReadAble"
    enable-create-form="dashboard-monitoring-rrv-hulu-migas-create"
    enable-view-form="dashboard-monitoring-rrv-hulu-migas-view"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@views/pages/monitoring/jalur-sungai/hulu-migas/YoTable.vue'

export default {
  name: 'RiverRouteHuluMigasTable',
  components: {
    YoTable
  },
  data() {
    return {
      columns: [
        {
          label: 'Status',
          field: 'form_hulu_miga.form_status'
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari No PBMBB'
          // }
        },
        {
          label: 'Kapal',
          field: 'form_hulu_miga.dm_river_boat_hm.boat_name'
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari Waktu Selesai'
          // },
        },
        // {
        //   label: 'Kegiatan',
        //   field: 'form_hulu_miga.kegiatan'
        //   // filterOptions: {
        //   //   enabled: true,
        //   //   placeholder: 'Cari Waktu Selesai'
        //   // },
        // },
        {
          label: 'Keterangan',
          field: 'form_hulu_miga.rencana_sandar',
          formatFn: this.dateHumanReadAble
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari Waktu Selesai'
          // },
        },
        // {
        //   label: 'Waktu Terakhir Kapal Sandar',
        //   field: 'tug_sandar_start_time',
        //   formatFn: this.dateHumanReadAble
        // },
        // {
        //   label: 'Waktu Terakhir Kapal Berangkat',
        //   field: 'tug_sandar_end_time',
        //   formatFn: this.dateHumanReadAble
        // }
        {
          label: 'Details',
          field: 'action'
        }
      ]
    }
  },
  methods: {
    moment,
    resolveFetchPayload() {
      return {
        API_SAL: 'form_chain_hm',
        serverParams: {
          sort: {
            type: 'DESC',
            field: 'created_at'
          },
          perPage: 7 // override
        }
      }
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    resolveFetchResponse(response) {
      return response.data.form_chain_hms
    }
  }
}
</script>
