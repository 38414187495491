var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('YoTable', {
    attrs: {
      "ref-specify": "YoTable",
      "columns": _vm.columns,
      "resolve-fetch-response": _vm.resolveFetchResponse,
      "resolve-fetch-payload": _vm.resolveFetchPayload,
      "there-is-group": false,
      "dateHumanReadAble": _vm.dateHumanReadAble,
      "enable-create-form": "dashboard-monitoring-rrv-hulu-migas-create",
      "enable-view-form": "dashboard-monitoring-rrv-hulu-migas-view"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }