var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  })])], 1), _c('b-col', {
    staticClass: "text-right mb-2",
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_vm.$store.state.app.roleName !== 'viewer' && !_vm.$can('SAL', '') && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "variant": 'primary',
      "to": {
        name: _vm.enableCreateForm,
        params: {
          previous_link: {
            name: 'dashboard-monitoring-rrv-hulu-migas'
          }
        }
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Buat Rencana Kedatangan Kapal Hulu Migas ")], 1)])]) : _vm._e()], 1)])], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "total-rows": _vm.total_records,
      "columns": _vm.columns,
      "rows": _vm.rows,
      "is-loading": _vm.yo_isLoading,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      },
      "select-options": {
        enabled: false,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        // perPageDropdown: [3, 7, 9],
        // dropdownAllowAll: false,
        // setCurrentPage: 1,
        jumpFirstOrLast: true,
        // firstLabel : 'First Page'
        // lastLabel : 'Last Page'
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        // ofLabel: 'of',
        // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',
        infoFn: function infoFn(params) {
          return "my own page ".concat(params.firstRecordOnPage);
        }
      },
      "group-options": {
        enabled: _vm.thereIsGroup
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered table-hover",
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "on-search": _vm.onSearchTable,
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-header-row",
      fn: function fn(props) {
        return [_vm._v(" Kegiatan "), _c('BBadge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('strong', [_vm._v(_vm._s(props.row.label))])]), _c('BBadge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v(" Jumlah Data : " + _vm._s(props.row.children.length) + " ")])];
      }
    }, {
      key: "table-row",
      fn: function fn(props) {
        var _props$row$form_chain, _props$row$form_hulu_, _props$row$form_hulu_3;
        return [props.row.pemilik ? _c('span', [props.column.field === 'form_hulu_miga.form_status' ? _c('span', [_c('h6', [_c('b-row', [_c('b-col', [_c('strong', [_c('b-badge', {
          staticClass: "d-block py-1",
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant(_vm.ftbStatus(props.row))
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.ftbStatus(props.row));
            }
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.ftbStatus(props.row))
          }
        })])], 1)])], 1)], 1), !props.row.no_rpkro_tb || !props.row.no_spog_tb ? _c('b-row', [_c('b-col', [_c('small', [_vm.$can('Hulu Migas', '') ? _c('span', [_c('b-spinner', {
          attrs: {
            "variant": 'warning',
            "type": "grow"
          }
        }), _c('strong', [_vm._v("Verifikasi (Warning)")]), _vm._v(": "), _c('ol', [!props.row.no_rpkro_tb ? _c('li', [_c('span', [_vm._v("Admin SAL Belum menerbitkan Nomor RPKRO")]), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), !props.row.no_spog_tb ? _c('li', [_c('span', [_vm._v("Belum menerbitkan Nomor SPOG")]), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e()])], 1) : _vm._e(), _vm.$can('SAL', '') ? _c('span', [_c('b-spinner', {
          attrs: {
            "variant": 'warning',
            "type": "grow"
          }
        }), _c('strong', [_vm._v("Verifikasi (Warning)")]), _vm._v(": "), _c('ol', [!props.row.no_rpkro_tb ? _c('li', [_c('span', [_vm._v("Admin SAL Belum menerbitkan Nomor RPKRO")]), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), !props.row.no_spog_tb ? _c('li', [_c('span', [_vm._v("Admin Hulu Migas belum menerbitkan Nomor SPOG")]), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e()])], 1) : _vm._e()])])], 1) : _vm._e()], 1) : props.column.field === 'form_hulu_miga.dm_river_boat_hm.boat_name' ? _c('span', [_c('strong', [_c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.tug_boat.name_tug_boat);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.tug_boat.name_tug_boat) + " ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Nomor PKK")])]), _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_pkk_tug_boat);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.no_pkk_tug_boat) + " ")])], 1)]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_c('strong', [_vm._v(" Kegiatan ")])])]), _c('span', [_c('strong', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText('TAMBAT');
            }
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s('TAMBAT')
          }
        })])], 1)])]) : props.column.field === 'form_hulu_miga.rencana_sandar' ? _c('span', [_c('strong', [_c('span', [_vm._v("[TugBoat] ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Rencana Sandar")])]), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.dateHumanReadAble(props.row.rencana_sandar));
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.rencana_sandar)) + " ")], 1)], 1), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Rencana Posisi Jetty")])]), _c('span', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": "light-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.rencana_position_jetty);
            }
          }
        }, [_vm._v(" #" + _vm._s(props.row.rencana_position_jetty) + " ")])], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": "light-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.rencana_baris_position_jetty);
            }
          }
        }, [_vm._v(" #" + _vm._s(props.row.rencana_baris_position_jetty) + " ")])], 1)], 1)], 1)]) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": _vm.ftbStatus(props.row) !== 'KAPAL BERANGKAT' ? _vm.ftbStatus(props.row) === 'KEGIATAN DIBATALKAN' ? 'outline-danger' : 'outline-warning' : 'outline-success',
            "block": "",
            "to": props.row.model_id != null ? {
              name: _vm.enableViewForm,
              params: {
                id: props.row.model_id,
                api: 'form_chain_hm',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-hulu-migas'
                }
              }
            } : {
              name: 'dashboard-monitoring-rrv-tug-boat-view',
              params: {
                id: props.row.id,
                api: 'form_tug_boat',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-hulu-migas'
                }
              }
            },
            "size": "lg"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": 'PenToolIcon',
            "size": "25"
          }
        }), _vm._v(" Realisasikan ")], 1)], 1), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "size": "lg",
            "to": props.row.model_id ? {
              name: 'dashboard-monitoring-rrv-hulu-migas-summary',
              params: {
                id: props.row.model_id,
                api: 'form_chain_hm',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-hulu-migas'
                }
              }
            } : {
              name: 'dashboard-monitoring-rrv-tug-boat-summary',
              params: {
                id: props.row.id,
                api: 'form_chain_hm',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-hulu-migas'
                }
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ActivityIcon",
            "size": "25"
          }
        }), _vm._v(" Summary ")], 1), _c('br'), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'light-primary'
          }
        }, [_c('strong', [props.row.model_id ? _c('span', [_vm._v(" #" + _vm._s(props.row.model_id) + " #" + _vm._s((_props$row$form_chain = props.row.form_chain_hm) === null || _props$row$form_chain === void 0 ? void 0 : _props$row$form_chain.form_hulu_migas_id) + " #" + _vm._s(props.row.id) + " ")]) : _c('span', [_vm._v(" #" + _vm._s(props.row.id))])])])], 1) : _c('span', [_c('strong', [_vm._v(_vm._s(props.formattedRow[props.column.field]))])])]) : _c('span', [props.column.field === 'form_hulu_miga.form_status' ? _c('span', [_c('h6', [_c('b-row', [_c('b-col', [_c('strong', [_c('b-badge', {
          staticClass: "d-block py-1",
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant((_props$row$form_hulu_ = props.row.form_hulu_miga) === null || _props$row$form_hulu_ === void 0 ? void 0 : _props$row$form_hulu_.form_status)
          },
          on: {
            "click": function click($event) {
              var _props$row$form_hulu_2;
              return _vm.copyText((_props$row$form_hulu_2 = props.row.form_hulu_miga) === null || _props$row$form_hulu_2 === void 0 ? void 0 : _props$row$form_hulu_2.form_status);
            }
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s((_props$row$form_hulu_3 = props.row.form_hulu_miga) === null || _props$row$form_hulu_3 === void 0 ? void 0 : _props$row$form_hulu_3.form_status)
          }
        })])], 1)])], 1)], 1), props.row.form_hulu_miga.fhm_keg_pipelines.length === 0 && props.row.form_hulu_miga.fhm_keg_manhs.length === 0 && props.row.form_hulu_miga.form_status !== 'KAPAL BERANGKAT' ? _c('h5', [_c('b-alert', {
          staticClass: "mt-1",
          attrs: {
            "variant": "warning",
            "show": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openModalRiver(_vm.data.item);
            }
          }
        }, [_c('div', {
          staticClass: "alert-body"
        }, [_c('strong', [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AlertTriangleIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s("RENCANA KEGIATAN BELUM DIISI")
          }
        })], 1)])])], 1) : _vm._e(), props.row.form_hulu_miga.realisasi_posisi_kapals.length > 1 ? _c('h5', [_c('b-alert', {
          staticClass: "mt-1",
          attrs: {
            "variant": "info",
            "show": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openModalRiver(_vm.data.item);
            }
          }
        }, [_c('div', {
          staticClass: "alert-body"
        }, [_c('strong', [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(" KAPAL PERNAH PINDAH")
          }
        })], 1)])])], 1) : _vm._e(), _vm.verifiedVariant(props.row.flag_verified) === 'warning' ? _c('b-row', [_c('b-col', [_c('small', [!_vm.$can('SAL', '') ? _c('span', [props.row.verification_hms.filter(function (data) {
          return data.flag_msg === 'tenant' && data.state_ok === false || data.flag_msg === 'public';
        }).length === 1 ? _c('span', [_c('b-spinner', {
          attrs: {
            "variant": _vm.verifiedVariant(props.row.flag_verified),
            "type": "grow"
          }
        }), _c('strong', [_vm._v("Verifikasi (" + _vm._s(props.row.flag_verified) + ")")]), _vm._v(": "), _c('ol', _vm._l(props.row.verification_hms.filter(function (data) {
          return data.flag_msg === 'tenant' || data.flag_msg === 'public';
        }), function (data, index) {
          return _c('li', {
            key: index
          }, [_c('span', {
            domProps: {
              "innerHTML": _vm._s(data.manual_message)
            }
          }), _c('feather-icon', {
            staticClass: "ml-25",
            attrs: {
              "icon": data.state_ok ? 'CheckCircleIcon' : 'XCircleIcon'
            }
          })], 1);
        }), 0)], 1) : _c('span'), props.row.verification_hms.filter(function (data) {
          return (data.flag_msg === 'sal' || data.flag_msg === 'public') && data.state_ok === false && !data.manual_message.includes('[TB]');
        }).length != 0 ? _c('span', [_c('b-spinner', {
          attrs: {
            "variant": _vm.verifiedVariant('Request Re-Verification'),
            "type": "grow"
          }
        }), _c('strong', [_vm._v("Informasi Document belum lengkap")]), _c('ol', _vm._l(props.row.verification_hms.filter(function (data) {
          return (data.flag_msg === 'sal' || data.flag_msg === 'public') && data.state_ok === false && !data.manual_message.includes('[TB]');
        }), function (data, index) {
          return _c('li', {
            key: index
          }, [_c('span', {
            domProps: {
              "innerHTML": _vm._s(data.manual_message)
            }
          }), _c('feather-icon', {
            staticClass: "ml-25",
            attrs: {
              "icon": data.state_ok ? 'CheckCircleIcon' : 'XCircleIcon'
            }
          })], 1);
        }), 0)], 1) : _vm._e()]) : _c('span', [_c('b-spinner', {
          attrs: {
            "variant": _vm.verifiedVariant(props.row.flag_verified),
            "type": "grow"
          }
        }), _c('strong', [_vm._v("Verifikasi (" + _vm._s(props.row.flag_verified) + ")")]), _vm._v(": "), _c('ol', _vm._l(props.row.verification_hms.filter(function (data) {
          return (data.flag_msg === 'sal' || data.flag_msg === 'public') && data.state_ok === false && !data.manual_message.includes('[TB]');
        }), function (data, index) {
          return _c('li', {
            key: index
          }, [_c('span', {
            domProps: {
              "innerHTML": _vm._s(data.manual_message)
            }
          }), _c('feather-icon', {
            staticClass: "ml-25",
            attrs: {
              "icon": data.state_ok ? 'CheckCircleIcon' : 'XCircleIcon'
            }
          })], 1);
        }), 0), props.row.verification_hms.filter(function (data) {
          return data.flag_msg === 'tenant' && data.state_ok === false || data.flag_msg === 'public';
        }).length === 1 ? _c('span', [_c('b-spinner', {
          attrs: {
            "variant": _vm.verifiedVariant('Request Re-Verification'),
            "type": "grow"
          }
        }), _c('strong', [_vm._v("Informasi Document belum lengkap :")]), _c('ol', _vm._l(props.row.verification_hms.filter(function (data) {
          return data.flag_msg === 'tenant' || data.flag_msg === 'public';
        }), function (data, index) {
          return _c('li', {
            key: index
          }, [_c('span', {
            domProps: {
              "innerHTML": _vm._s(data.manual_message)
            }
          }), _c('feather-icon', {
            staticClass: "ml-25",
            attrs: {
              "icon": data.state_ok ? 'CheckCircleIcon' : 'XCircleIcon'
            }
          })], 1);
        }), 0)], 1) : _vm._e()], 1)])])], 1) : _vm._e()], 1) : props.column.field === 'form_hulu_miga.dm_river_boat_hm.boat_name' ? _c('span', [_c('strong', [_c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_hulu_miga.dm_river_boat_hm.boat_name);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.form_hulu_miga.dm_river_boat_hm.boat_name) + " ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Nomor PKK")])]), _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_hulu_miga.no_pkk);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.form_hulu_miga.no_pkk) + " ")])], 1), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Kegiatan")])]), _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_hulu_miga.kegiatan);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.form_hulu_miga.kegiatan) + " ")])], 1)])]) : props.column.field === 'form_hulu_miga.rencana_sandar' ? _c('span', [_c('strong', [_c('span', [_vm._v("[" + _vm._s(props.row.form_hulu_miga.jenis_kapal) + "] ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Rencana Sandar")])]), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.dateHumanReadAble(props.row.form_hulu_miga.rencana_sandar));
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.form_hulu_miga.rencana_sandar)) + " ")], 1), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Rencana Posisi Jetty")])]), _c('span', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": "light-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_hulu_miga.rencana_position_jetty);
            }
          }
        }, [_vm._v(" #" + _vm._s(props.row.form_hulu_miga.rencana_position_jetty) + " ")])], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": "light-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_hulu_miga.rencana_baris_position_jetty);
            }
          }
        }, [_vm._v(" #" + _vm._s(props.row.form_hulu_miga.rencana_baris_position_jetty) + " ")])], 1)], 1)], 1)], 1)]) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": props.row.form_hulu_miga.form_status !== 'KAPAL BERANGKAT' ? 'outline-warning' : 'outline-success',
            "to": {
              name: _vm.enableViewForm,
              params: {
                id: props.row.id,
                api: 'form_chain_hm',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-hulu-migas'
                },
                kegiatanDetail: _vm.currentlyKegiatanKapal(props.row.form_hulu_miga)
              }
            },
            "size": "lg",
            "block": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": props.row.form_hulu_miga.form_status === 'NO PERIJINAN BELUM LENGKAP' ? 'PenToolIcon' : 'PenToolIcon',
            "size": "25"
          }
        }), _vm._v(" Realisasikan ")], 1), _c('br'), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "size": "lg",
            "to": {
              name: 'dashboard-monitoring-rrv-hulu-migas-summary',
              params: {
                id: props.row.id,
                api: 'form_chain_hm',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-hulu-migas'
                }
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ActivityIcon",
            "size": "25"
          }
        }), _vm._v(" Summary ")], 1), _c('br'), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'light-primary'
          }
        }, [_c('strong', [props.row.form_hulu_migas_id ? _c('span', [_vm._v(" #" + _vm._s(props.row.id) + " #" + _vm._s(props.row.form_hulu_migas_id) + " #" + _vm._s(props.row.form_tug_boat_id) + " ")]) : _c('span', [_vm._v(" #" + _vm._s(props.row.id))])])])], 1)]) : _c('span', [_c('strong', [_vm._v(_vm._s(props.formattedRow[props.column.field]))])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }